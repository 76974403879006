<template>
  <div>
    <v-menu v-if="can('admin')"
            bottom
            right
            transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-3"
               icon
               v-bind="attrs"
               v-on="on">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </template>

      <v-list>

        <v-list-item-group color="primary">

          <v-list-item to="/fees/create">
            <v-list-item-avatar size="17">
              <v-icon dense>mdi-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ajouter un frai de service</v-list-item-title>
          </v-list-item>

          <v-list-item to="/promotions/create">
            <v-list-item-avatar size="17">
              <v-icon dense>mdi-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ajouter une promotion</v-list-item-title>
          </v-list-item>

          <v-list-item to="/promo-codes/create">
            <v-list-item-avatar size="17">
              <v-icon dense>mdi-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ajouter un code promo</v-list-item-title>
          </v-list-item>

          <v-divider class="my-2"/>

          <v-list-item @click="$refs.addUserDialog.open()">
            <v-list-item-avatar size="17">
              <v-icon dense>mdi-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title> Ajouter un utilisateur</v-list-item-title>
          </v-list-item>

        </v-list-item-group>

      </v-list>
    </v-menu>

    <AddUserDialog ref="addUserDialog"/>

  </div>
</template>

<script>
import AddUserDialog from "@/views/users/FormDialog.vue";


export default {
    components: {AddUserDialog}
}
</script>

<style scoped>

</style>