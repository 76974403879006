import Vue from "vue";

let currantData = new Vue({
    data: {
        $Nprogress: false,
        $confirm_dialog: false,

        $is_confirm: false,
        $confirm_dialog_title: 'CONFIRMATION',
        $confirm_dialog_body: 'Êtes-vous sûr de vouloir SUPPRIMER cet élément',
        $btn_confirm_dialog: 'OK',
        $btn_cancel_dialog: 'Annuler',
        $successMessage: '',
        $errorMessage: '',

        $preview_image_dialog: false,
        $preview_image_file: null,

        $martship_states: {
            texts: {
                PENDING: "En Attente",
                OK: "Acceptée",
                REFUNDED: "Refusée",
            },
            colors: {
                PENDING: "primary",
                OK: "success",
                REFUNDED: "red"
            },
        },

        $states: {
            texts: {
                PENDING: "En Attente",
                ACCEPTED: "Acceptée",
                REFUSED: "Refusée",
            },
            colors: {
                PENDING: "primary",
                ACCEPTED: "success",
                REFUSED: "red"
            },
        },

        $amountRules: [
            v => !!v || "Ce champ est obligatoire",
            v => (v && !isNaN(parseFloat(v) && v > 0)) || "Entrer un nombre valide",

        ],
        $pubTypes: [
            {
                id: 0,
                name: "Banner",
            },
            {
                id: 1,
                name: "POP UP",
            },
            {
                id: 2,
                name: "Full Screen",
            },
        ],
        $pubCycles: [
            {
              label: "Une fois seulement",
              value: {
                number_times: 1,
                days_interval: 0,
                hours_interval: 0,
              },
            },
            {
              label: "Chaque Jour",
              value: {
                number_times: 1,
                days_interval: 1,
                hours_interval: 0,
              },
            },
            {
              label: "Chaque Semaine",
              value: {
                number_times: 1,
                days_interval: 7,
                hours_interval: 0,
              },
            },
          ],
    },
});
Vue.mixin({
    methods: {
        CurrencyFormatting(text) {
            if (text == null)
                text = "0"
            let dollarUSLocale = Intl.NumberFormat('us-US');
            return dollarUSLocale.format(text);
            // return text.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        },
        getPercent(value1, valueTotal) {
            if (valueTotal == 0 || valueTotal == null) return 0;
            if (value1 == 0 || value1 == null) return 0;
            return Math.round((value1 / valueTotal) * 100);
        },

        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },
        in_array(array, item) {
            return array.some(function (field) {
                return field === item;
            });
        },
        previewImage(file) {
            this.$preview_image_dialog = true;
            this.$preview_image_file = file;

        },
        widthGraph(table) {
            var width = 0;
            if (table) {
                width = table.length * 100;

                if (width < 400) {
                    width = 400;
                }
            }
            return width;
        },
        base_url() {
            return process.env.VUE_APP_FILE_URL;
        },

        publicityPositionings() {
            return [
                "HOME_TOP",
                "HOME_BOTTOM",
                "HOME_BOTTOM",
                "MARKETPLACE",
                "HISTORY",
                "NOTIFICATION"
            ];
        }


    },

    computed: {
        $Nprogress: {
            get: function () {
                return currantData.$data.$Nprogress
            },
            set: function (boolean) {
                currantData.$data.$Nprogress = boolean;
            }
        },
        $confirm_dialog: {
            get: function () {
                return currantData.$data.$confirm_dialog
            },
            set: function (boolean) {
                currantData.$data.$confirm_dialog = boolean;
            }
        },
        $is_confirm: {
            get: function () {
                return currantData.$data.$is_confirm
            },
            set: function (boolean) {
                currantData.$data.$is_confirm = boolean;
            }
        },
        $confirm_dialog_title: {
            get: function () {
                return currantData.$data.$confirm_dialog_title
            },
            set: function (data) {
                currantData.$data.$confirm_dialog_title = data;
            }
        },
        $confirm_dialog_body: {
            get: function () {
                return currantData.$data.$confirm_dialog_body
            },
            set: function (data) {
                currantData.$data.$confirm_dialog_body = data;
            }
        },
        $btn_confirm_dialog: {
            get: function () {
                return currantData.$data.$btn_confirm_dialog
            },
            set: function (data) {
                currantData.$data.$btn_confirm_dialog = data;
            }
        },
        $btn_cancel_dialog: {
            get: function () {
                return currantData.$data.$btn_cancel_dialog
            },
            set: function (data) {
                currantData.$data.$btn_cancel_dialog = data;
            }
        },
        $successMessage: {
            get: function () {
                return currantData.$data.$successMessage
            },
            set: function (data) {
                currantData.$data.$successMessage = data;
            }
        },
        $errorMessage: {
            get: function () {
                return currantData.$data.$errorMessage
            },
            set: function (data) {
                currantData.$data.$errorMessage = data;
            }
        },

        $preview_image_dialog: {
            get: function () {
                return currantData.$data.$preview_image_dialog
            },
            set: function (boolean) {
                currantData.$data.$preview_image_dialog = boolean;
            }
        },

        $preview_image_file: {
            get: function () {
                return currantData.$data.$preview_image_file
            },
            set: function (file) {
                currantData.$data.$preview_image_file = file;
            }
        },

        $martship_states: {
            get: function () {
                return currantData.$data.$martship_states
            },
            set: function (data) {
                currantData.$data.$martship_states = data;
            }
        },

        $states: {
            get: function () {
                return currantData.$data.$states
            },
            set: function (data) {
                currantData.$data.$states = data;
            }
        },

        $amountRules: {
            get: function () {
                return currantData.$data.$amountRules
            },
            set: function (data) {
                currantData.$data.$amountRules = data;
            }
        },
        pubTypes: {
            get: function () {
                return currantData.$data.$pubTypes
            },
            set: function (data) {
                currantData.$data.$pubTypes = data;
            }
        },

        pubCycles: {
            get: function () {
                return currantData.$data.$pubCycles
            },
            set: function (data) {
                currantData.$data.$pubCycles = data;
            }
        }
    }
});