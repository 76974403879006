<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">
      <form @submit.prevent="save">
        <v-card :disabled="btnLoading" :loading="btnLoading">
          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-8">

            <v-select v-if="!can('supplier_manager')"
                      v-model="form.database_id"
                      :error-messages="errors.database_id"
                      :items="databases"
                      :loading="isDBLoading"
                      item-text="name"
                      item-value="id"
                      label="Base de données"
            />

            <v-menu v-if="!can('supplier_manager')"
                    v-model="menu"
                    :close-on-content-click="false"
                    offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="gifty" text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-database-plus</v-icon>
                  Ajouter une base de données
                </v-btn>
              </template>
              <v-card :loading="addDBLoading">
                <v-card-text>
                  <v-text-field
                      v-model="databaseName"
                      :error-messages="dbErrors.name"
                      placeholder="Entrez le nom ici..."
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="gifty" text @click="menu = false">
                    Annuler
                  </v-btn>
                  <v-btn
                      :loading="addDBLoading"
                      color="gifty"
                      dark
                      depressed
                      @click="saveDatabase"
                  >
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-text-field
                v-model="form.name"
                :error-messages="errors.name"
                label="Nom complet *"
            />

            <v-text-field v-model="form.phone"
                          :error-messages="errors.phone"
                          label="Téléphone *"
            />

            <v-select v-if="!can('supplier_manager')"
                      v-model="form.role_id"
                      :error-messages="errors.role_id"
                      :items="roles"
                      :loading="isRoleLoading"
                      item-text="name"
                      item-value="id"
                      label="Rôle *"
            />

            <v-text-field
                v-model="form.email"
                :error-messages="errors.email"
                label="Email *"
            />

            <v-text-field
                v-model.trim="form.username"
                :error-messages="errors.username"
                label="Nom d'utilisateur *"
            />

            <v-text-field v-model.trim="form.password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :error-messages="errors.password"
                          :type="show ? 'text' : 'password'"
                          label="Mot de pass *"
                          @click:append="show = !show"
            />

            <div class="d-flex align-center">
              <v-file-input
                  ref="file"
                  :error-messages="errors.image"
                  accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                  chips
                  label="Image *"
                  prepend-icon="mdi-camera"
                  show-size
                  @change="uploadFile"
                  @focus="errors && errors.image ? (errors.image = '') : false"
              />
              <v-btn
                  v-if="form.image"
                  class="primary white--text ml-2"
                  depressed
                  icon
                  small
                  @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <div
                v-if="user_picture"
                :class="
                $vuetify.theme.dark ? 'secondary lighten-1' : 'grey lighten-3'
              "
                class="d-flex justify-space-between pa-3 mb-2 rounded"
            >
              <v-avatar size="40">
                <v-img
                    :src="getImageLink(user_picture)"
                    class="rounded zoom-pointer"
                />
              </v-avatar>
              <v-spacer/>
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn
                :loading="btnLoading"
                color="primary"
                depressed
                type="submit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment";

export default {
  data() {
    return {
      title: null,
      dialog: false,
      isDBLoading: false,
      isRoleLoading: false,
      isCategoryLoading: false,
      isCountriesLoading: false,
      isWilayasLoading: false,
      btnLoading: false,
      show: false,

      dbErrors: {},
      menu: false,
      databaseName: null,
      addDBLoading: false,

      databases: [],
      roles: [],
      categories: [],
      countries: [],
      wilayas: [],

      errors: {},
      form: {
        id: "",
        name: "",
        phone: "",
        email: "",
        username: "",
        password: "",
        role_id: "",
        country_id: "",
        wilaya_id: "",
        image: "",
        gender: "",
        dob: moment().format("YYYY-MM-DD"),
      },

      user_picture: "",
      genders: [
        {
          id: "male",
          name: "Homme",
        },
        {
          id: "female",
          name: "Femme",
        },
      ],
      dateMenu: false,
    };
  },
  methods: {
    open(item) {
      if (this.$refs.file) {
        this.$refs.file.reset();
      }
      this.errors = {};
      if (item) {
        this.title = "Modifier un utilisateur";

        this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          username: item.username,
          role_id: item.role.id,
          category_id: item.category_id,
          country_id: item.country_id,
          wilaya_id: item.wilaya_id,
          gender: item.gender,
          image: "",
          password: "",
        };

        if (item.dob) {
          const date = moment(item.dob, "DD-MM-YYYY");
          this.form.dob = date.format("YYYY-MM-DD");
        }

        this.user_picture = item.image;
      } else {
        this.title = "Ajouter un utilisateur";
        this.form = {
          id: "",
        };
      }

      this.dialog = true;

      if (this.roles && !this.roles.length) {
        this.getRoles();
      }
      if (!this.databases.length) {
        this.getDatabases();
      }

      if (!this.categories.length) {
        // this.getCategories();
      }
      if (!this.countries.length) {
        // this.getCountries();
      }
      if (!this.wilayas.length) {
        // this.getWilayas();
      }
    },
    close() {
      this.$refs.file.reset();
      this.dialog = false;
    },
    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get("/v1/users/countries")
          .then((res) => {
            this.isCountriesLoading = false;
            this.countries = res.data.data;
          })
          .catch((err) => {
            this.isCountriesLoading = false;
            console.log(err);
          });
    },
    getCategories() {
      this.isCategoryLoading = true;
      HTTP.get("/v1/users/categories")
          .then((res) => {
            this.isCategoryLoading = false;
            this.categories = res.data.data;
          })
          .catch((err) => {
            this.isRoleLoading = false;
            console.log(err);
          });
    },

    getWilayas() {
      this.isWilayasLoading = true;
      HTTP.get("/v1/users/wilayas")
          .then((res) => {
            this.isWilayasLoading = false;
            this.wilayas = res.data.data;
          })
          .catch((err) => {
            this.isWilayasLoading = false;
            console.log(err);
          });
    },
    getRoles() {
      this.isRoleLoading = true;
      HTTP.get("/v1/users/roles")
          .then((res) => {
            this.isRoleLoading = false;
            this.roles = res.data.data;
          })
          .catch((err) => {
            this.isRoleLoading = false;
            console.log(err);
          });
    },
    getDatabases() {
      this.isDBLoading = true;
      HTTP.get("/v1/users/databases")
          .then((res) => {
            this.isDBLoading = false;
            this.databases = res.data.data;
          })
          .catch((err) => {
            this.isDBLoading = false;
            console.log(err);
          });
    },
    save() {
      this.errors = {};
      this.isLoading = true;
      this.btnLoading = true;

      let methode = this.form.id ? "update" : "store";

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      HTTP.post("/v1/users/" + methode, formData)
          .then(() => {
            this.isLoading = false;
            this.btnLoading = false;
            this.$successMessage = "Cet élément a été enregistré avec succès.";
            this.close();
            this.$emit("refresh");

            this.$refs.file.reset();
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
            this.isLoading = false;
            this.btnLoading = false;
            console.log(err);
          });
    },
    saveDatabase() {
      this.dbErrors = {};
      this.addDBLoading = true;
      let data = {
        name: this.databaseName,
      };
      HTTP.post("/v1/users/databases/store", data)
          .then(() => {
            this.addDBLoading = false;
            this.menu = false;
            this.$successMessage = "Cet élément a été enregistré avec succès.";
            this.getDatabases();
          })
          .catch((err) => {
            this.dbErrors = err.response.data.errors;
            this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
            this.addDBLoading = false;
            console.log(err);
          });
    },

    uploadFile(file) {
      this.form.image = file;
    },
  },
};
</script>