import axios from 'axios';
import store from "@/store";
import router from "@/routes";

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: false,
    headers: {
        Authorization: 'Bearer ' + store.state.user.access_token,
        'Content-Type': "application/json",
        'Accept': "application/json",
        'X-Requested-With': "XMLHttpRequest",
        'Access-Control-Allow-Origin': "*",
    },
})

HTTP.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        store.dispatch('user', '').then().catch()
        store.dispatch('isLoggedIn', false).then().catch()
        if (router.currentRoute.name !== 'login') {
            window.location.href = '/login'
        }
    }
    return Promise.reject(error)
})

