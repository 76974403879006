<template>
  <div id="app">
    <v-app :class="!$vuetify.theme.dark ? 'bg-light' : ''" class="d-flex">
      <div id="leftNav" ref="left_navigation" class="noprint">
        <navigation v-if="isLoggedIn && !can('pos | salepoint')"/>
      </div>
      <v-main>
        <v-row>
          <v-col cols="12">
            <success-message/>
            <error-message/>
            <confirm-dialog/>
            <PreviewImageDialog/>
            <router-view/>
            <v-btn v-show="fab"
                   v-scroll="onScroll"
                   bottom
                   class="rounded noprint"
                   color="primary"
                   dark
                   fab
                   fixed
                   right
                   small
                   @click="toTop">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
      <app-footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/navigation/navigation";
import AppFooter from "@/navigation/app-footer";
import ConfirmDialog from "@/components/confirm-dialog";
import SuccessMessage from "@/components/successMessage";
import ErrorMessage from "@/components/errorMessage";

import PreviewImageDialog from "./components/PreviewImageDialog.vue";

export default {
    components: {
        ErrorMessage,
        SuccessMessage,
        ConfirmDialog,
        AppFooter,
        Navigation,
        PreviewImageDialog,
    },
    data() {
        return {
            fab: false,
            isLoggedIn: this.$store.state.isLoggedIn,
            sideBarWidth: 0
        };
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
    },
};
</script>

<style lang="scss">
</style>
