<template>
    <div>
  
      <InfoDialog ref="infoDialog"/>
  
      <v-dialog
          v-model="modal"
          persistent
          width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="dates.join(' ~ ')"
                        label="Dates"
                        class="required"
                        readonly
                        v-bind="attrs" v-on="on">
            <v-icon slot="prepend-inner"
                    color="primary">
              mdi-calendar
            </v-icon>
            <v-icon slot="append"
                    v-if="textInfo"
                    @click="$refs.infoDialog.open(textInfo)"
                    color="primary">
              mdi-information
            </v-icon>
          </v-text-field>
        </template>
        <v-date-picker
            :min="dateNow"
            range
            v-model="selectedDates"
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="modal = false"
          >
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>
          <v-btn
              :disabled="currentDates.length < 2"
              text
              color="primary"
              @click="save"
          >
            <v-icon left>mdi-check</v-icon>
            Valider
          </v-btn>
        </v-date-picker>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  
  export default {
    props: ['dates', 'textInfo'],
    data() {
      return {
        modal: false,
        dateNow: new Date().toJSON().slice(0, 10),//this.$moment().format('YYYY-MM-DD'),
        currentDates: this.dates,
      }
    },
    methods: {
      save() {
        this.modal = false
        this.$emit('dates', this.selectedDates)
      },
      setValue(value){
        this.currentDates = value
      }
    },
    computed: {
      selectedDates: {
        get() {
          return this.currentDates
        },
        set(value) {
          this.currentDates = value;
        }
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>