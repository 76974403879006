import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {HTTP} from "@/http-common";

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        miniVariant: false,
        mobileDrawerState: false,
        dark_theme: false,
        user: "",
        isLoading: false,
        isLoggedIn: false,
        users: [],
        stores: [],
        salepoints: [],
        databases: {},
        database: {},
        dashboardData: {
            Last_month_total: "0",
            balance: 0,
            consumed_alltime: 0,
            last_transactions: [],
            salepoint_count: 0,
            this_month_total: "0",
            total_amount_transactions: null,
            total_transactions: 0,
            rasaction_evolutions: {dates: [], amounts: []},
            total_databases: 0
        },
        service_states: [],
        services: [],
        wilayas: [],
        communes: [],
        transactions: {},
        voucher_orders: {},

        // analyses
        accounts_statistiques: [],
        totalsAccounts: {},

        // vouchers
        statsLoading: false,
        suppliersLoading: false,
        ownersLoading: false,

        suppliers: [],
        voucher_owners: [],
        vouchers: [],
        statsVouchers: [],

        analysesVouchers: {},

        // services type
        service_types: [],

        // cards
        cards: [],// cartds types
        list_cards: [],
        cards_statistiques: {},

        publicities: [],
        publicity: {},

        // invoices
        invoices: [],
        invoice: {},
        invoiceVouchers: [],
        invoiceLoading: false,
        invoicesRefills: [],
        invoiceRefill: {},
        invoices_refills_statistiques: [],

        // clients
        clientsLoading: false,
        clients: [],

        // Commission
        commissions: [],
        commissionsLoading: false,

        analysesVouchersAccount: {},
        analysesDrivers: {},
        driverTransactions: {},
        isLoadingTransaction: false,
        accountRefills: {},
        isLoadingRefills: false,

        // categories
        categories: [],
        categoriesLoading: false,

        // ecommerce
        ecommerceModels: [],
        ecommerceModelsLoading: false,

        ecommerceApis: [],
        ecommerceApisLoading: false,

        home: "/",
        accountLoading: false,
    },
    getters: {
        getUsers: (state) => state.users,
        getLoading: (state) => state.isLoading,
        getUser: (state) => state.user,
        getSalepoints: (state) => state.salepoints,
        getWilayas: (state) => state.wilayas,
        getCommunes: (state) => state.communes,
        getDatabases: (state) => state.databases,
        getDatabase: (state) => state.database,
        getDashboardData: (state) => state.dashboardData,
        getTransactions: (state) => state.transactions,
        getVoucherOrders: (state) => state.voucher_orders,
        getServices: (state) => state.services,
        getServiceStates: (state) => state.service_states,

        // analyses
        getAccountsStatistiques: (state) => state.accounts_statistiques,
        getAcountsTotals: (state) => state.totalsAccounts,

        // vouchers
        getVouchers: (state) => state.vouchers,
        getAnalysesVouchers: (state) => state.analysesVouchers,

        getAnalysesVouchersAccount: (state) => state.analysesVouchersAccount,


        getSuppliers: (state) => state.suppliers,
        getStatsVouchers: (state) => state.statsVouchers,
        getVoucherOwners: (state) => state.voucher_owners,

        getStatsLoading: (state) => state.statsLoading,
        getSuppliersLoading: (state) => state.suppliersLoading,

        ownersLoading: (state) => state.ownersLoading,

        // servicesTypes
        getServiceTypes: (state) => state.service_types,

        // cards
        getCards: (state) => state.cards,

        getListCards: (state) => state.list_cards,

        getCardsStatistiques: (state) => state.cards_statistiques,

        // get publicities
        getPublicities: (state) => state.publicities,
        getPublicity: (state) => state.publicity,

        // get invoices
        getInvoices: (state) => state.invoices,
        getInvoice: (state) => state.invoice,
        getInvoiceVouchers: (state) => state.invoiceVouchers,

        invoiceLoading: (state) => state.invoiceLoading,

        // invoices Refills Clients
        getInvoicesRefills: (state) => state.invoicesRefills,
        getClients: (state) => state.clients,
        clientsLoading: (state) => state.clientsLoading,
        getInvoiceRefill: (state) => state.invoiceRefill,

        getInvoicesRefillsStatistiques: (state) => state.invoices_refills_statistiques,

        getCommissions: (state) => state.commissions,
        commissionsLoading: (state) => state.commissionsLoading,
        getAnalysesDrivers: (state) => state.analysesDrivers,
        getDriverTransactions: (state) => state.driverTransactions,

        isLoadingTransaction: (state) => state.isLoadingTransaction,

        isLoadingRefills: (state) => state.isLoadingRefills,
        getAccountRefills: (state) => state.accountRefills,

        // categeories
        getCategories: (state) => state.categories,
        categoriesLoading: (state) => state.categoriesLoading,

        // Ecommerce
        getEcommerceModels: (state) => state.ecommerceModels,
        ecommerceModelsLoading: (state) => state.ecommerceModelsLoading,

        //Apis
        getEcommerceApis: (state) => state.ecommerceApis,
        ecommerceApisLoading: (state) => state.ecommerceApisLoading,

        accountLoading: (state) => state.accountLoading,


    },
    mutations: {
        dark_theme: (state, boolean) => {
            state.dark_theme = boolean;
        },
        updateUserPicture: (state, photo) => {
            state.user.user.account.photo = photo;
        },
        setUserBalance: (state, data) => {
            state.user.user.account.all_balance = data;
        },
        phoneConfirmed: (state) => {
            state.user.user.phone_confirmed = true;
        },
        user: (state, data) => {
            state.user = data;
            if (data === "" || data == null) {
                state.home = "/login";
            } else {
                if (data.user.role.name === "supplier") {
                    state.home = "/analyses_vouchers"
                } else if (data.user.role.name === "store") {
                    state.home = '/partner-vouchers/store/' + store.state.user.user.id
                } else if (data.user.role.name === "pos" || data.user.role.name === "salepoint") {
                    state.home = '/gifty'
                } else if (data.user.role.name === "support") {
                    state.home = '/support'
                } else if (data.user.role.name === "client") {
                    state.home = '/client'
                } else if (data.user.role.name === "order_manager") {
                    state.home = '/voucher-order'
                } else if (data.user.role.name === "booster") {
                    state.home = '/boostings'
                } else if (data.user.role.name === "supplier_manager") {
                    state.home = '/users'
                } else if (data.user.role.name === "order_manager") {
                    state.home = '/paying-bills'
                } else {
                    state.home = "/";
                }
            }
        },

        setStore: (state, data) => {
            state.user.user.salepoint = data;
        },

        refreshUser: (state, data) => {
            state.user.user = data;
        },
        stores: (state, data) => {
            state.stores = data;
        },
        isLoggedIn: (state, boolean) => {
            state.isLoggedIn = boolean;
        },
        isLoading: (state, boolean) => {
            state.isLoading = boolean;
        },
        set_dashboardData: (state, data) => {
            state.dashboardData = data;
        },
        setSalepoints: (state, data) => {
            state.salepoints = data;
        },
        setDatabases: (state, data) => {
            state.databases = data;
        },

        setDatabase: (state, data) => {
            state.database = data;
        },
        setWialayas: (state, data) => {
            state.wilayas = data;
        },
        setCommunes: (state, data) => {
            state.communes = data;
        },

        setTransactions: (state, data) => {
            state.transactions = data;
        },
        setVoucherOrders: (state, data) => {
            state.voucher_orders = data;
        },

        setServices: (state, data) => {
            state.services = data;
        },

        setServiceStates: (state, data) => {
            state.service_states = data;
        },

        setServiceTypes: (state, data) => {
            state.service_types = data;
        },

        // analyse
        setAcountsStatistiques: (state, data) => {
            state.accounts_statistiques = data
        },
        setAcountsTotals: (state, data) => {
            state.totalsAccounts = data
        },

        // vouchers
        setVouchers: (state, data) => {
            state.vouchers = data
        },

        setAnalysesVouchers: (state, data) => {
            state.analysesVouchers = data
        },

        setSuppliers: (state, data) => {
            state.suppliers = data
        },

        setStatsVouchers: (state, data) => {
            state.statsVouchers = data
        },

        statsLoading: (state, boolean) => {
            state.statsLoading = boolean;
        },

        suppliersLoading: (state, boolean) => {
            state.suppliersLoading = boolean;
        },

        setVoucherOwners: (state, data) => {
            state.voucher_owners = data;
        },

        ownersLoading: (state, boolean) => {
            state.ownersLoading = boolean
        },

        // cards
        setCards: (state, data) => {
            state.cards = data
        },

        setListCards: (state, data) => {
            state.list_cards = data
        },

        setCardsStatistiques: (state, data) => {
            state.cards_statistiques = data
        },

        // publicities
        setPublicities: (state, data) => {
            state.publicities = data
        },

        setPublicity: (state, data) => {
            state.publicity = data
        },
        //update account
        updateUserAccount: (state, data) => {
            state.user.user.name = data.name
            state.user.user.username = data.username
            state.user.user.email = data.email
        },

        // invoices
        setInvoices: (state, data) => {
            state.invoices = data
        },
        setInvoice: (state, data) => {
            state.invoice = data
        },
        setInvoiceVouchers: (state, data) => {
            state.invoiceVouchers = data
        },
        setInvoiceLoading: (state, data) => {
            state.invoiceLoading = data
        },

        // Invoices Refills
        setInvoicesRefills: (state, data) => {
            state.invoicesRefills = data
        },
        setInvoiceRefill: (state, data) => {
            state.invoiceRefill = data
        },
        // clients
        setClients: (state, data) => {
            state.clients = data
        },

        clientsLoading: (state, data) => {
            state.clientsLoading = data
        },

        setInvoicesRefillsStatistiques: (state, data) => {
            state.invoices_refills_statistiques = data
        },

        setCommissions: (state, data) => {
            state.commissions = data
        },
        commissionsLoading: (state, data) => {
            state.commissionsLoading = data
        },

        setAnalysesVouchersAccount: (state, data) => {
            state.analysesVouchersAccount = data
        },

        setAnalysesDrivers: (state, data) => {
            state.analysesDrivers = data
        },
        setDriverTransactions: (state, data) => {
            state.driverTransactions = data
        },

        isLoadingTransaction: (state, data) => {
            state.isLoadingTransaction = data
        },

        isLoadingRefills: (state, data) => {
            state.isLoadingRefills = data
        },
        setAccountRefills: (state, data) => {
            state.accountRefills = data
        },
        // categeories
        setCategories: (state, data) => {
            state.categories = data
        },
        categoriesLoading: (state, data) => {
            state.categoriesLoading = data
        },
        // Ecommerce
        setEcommerceModels: (state, data) => {
            state.ecommerceModels = data
        },
        ecommerceModelsLoading: (state, data) => {
            state.ecommerceModelsLoading = data
        },
        setEcommerceApis: (state, data) => {
            state.ecommerceApis = data
        },

        ecommerceApisLoading: (state, data) => {
            state.ecommerceApisLoading = data
        },

        setAccount: (state, data) => {
            state.user.user.account = data
        },
        accountLoading: (state, data) => {
            state.accountLoading = data
        }

    },
    actions: {

        phoneConfirmed({commit}) {
            commit("phoneConfirmed");
        },
        refreshUser({commit}, data) {
            commit("refreshUser", data);
        },
        dark_theme({commit}, boolean) {
            commit("dark_theme", boolean);
        },
        user({commit}, data) {
            commit("user", data);
        },
        async setStore({commit}, data) {
            commit("setStore", data);
        },
        stores({commit}, data) {
            commit("stores", data);
        },

        isLoggedIn({commit}, boolean) {
            commit("isLoggedIn", boolean);
        },
        isLoading({commit}, boolean) {
            commit("isLoading", boolean);
        },

        async fetchDashboardDatafetchUsers({commit}) {
            commit("isLoading", true);

            HTTP.get("/dashboard/data", {params: {}})
                .then((res) => {
                    commit("set_dashboardData", res.data);

                    commit("isLoading", false);
                })
                .catch((err) => {
                    commit("isLoading", false);
                });
        },

        async fetchSalepoints({commit}, {data, id}) {

            commit("isLoading", true);

            HTTP.get("/database/show/" + id, {
                params: {
                    keyword: data.keyword,
                    wilaya_ids: JSON.stringify(data.selectedWilayas),
                    roles: JSON.stringify(data.roles),
                    categories: JSON.stringify(data.categories),
                    commune_ids: JSON.stringify(data.selectedCommunes),
                    has_gifty: data.has_gifty,
                    size: data.size,
                    page: data.page,
                }
            })
                .then((res) => {

                    console.log(res.data.data);
                    commit("setSalepoints", res.data.salepoints);
                    commit("setDatabase", res.data);
                    commit("isLoading", false);
                })
                .catch((err) => {
                    commit("isLoading", false);
                });
        },
        async fetchwilayas({commit}) {
            commit("isLoading", true);

            HTTP.get("/wilaya/all", {params: {}})
                .then((res) => {
                    commit("setWialayas", res.data);

                    commit("isLoading", false);
                })
                .catch((err) => {
                    commit("isLoading", false);
                });
        },

        async fetchCommunes({commit}, {filter}) {

            HTTP.get("/commune", {params: {filter: JSON.stringify(filter)}})
                .then((res) => {
                    console.log(res.data)
                    commit("setCommunes", res.data);


                })
                .catch((err) => {

                });
        },

        async fetchTransactions({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/refill/sent", {
                params: {
                    ...filter
                }
            })
                .then((res) => {
                    commit("isLoading", false);
                    //console.log(res.data)
                    commit("setTransactions", res.data);


                })
                .catch((err) => {
                    commit("isLoading", false);
                });
        },


        async fetchVoucherOrders({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/voucher/order", {
                params: {

                    size: filter.size,
                    page: filter.page,
                    startDate: filter.startDate,
                    endtDate: filter.endtDate,
                    status: filter.status,
                }
            })
                .then((res) => {
                    commit("isLoading", false);
                    //console.log(res.data)
                    commit("setVoucherOrders", res.data);


                })
                .catch((err) => {
                    commit("isLoading", false);
                });

        },

        async fetchDatabases({commit}) {
            commit("isLoading", true);

            HTTP.get("/database", {params: {}})
                .then((res) => {
                    commit("setDatabases", res.data.data);

                    commit("isLoading", false);
                })
                .catch((err) => {
                    commit("isLoading", false);
                });
        },

        async fetchServices({commit}, {filter}) {
            commit("isLoading", true);

            HTTP.get("/service", {
                params: {
                    ...filter
                }
            }).then((res) => {
                commit("setServices", res.data);
                commit("isLoading", false);

            }).catch((err) => {
                commit("isLoading", false);
            })

        },

        async fetchServiceTypes({commit}) {
            commit("isLoading", true);
            HTTP.get("/service-type", {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setServiceTypes", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchServiceStates({commit}) {
            commit("isLoading", true);
            HTTP.get("/service-state", {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setServiceStates", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // totals
        async fetchAccountsStatistiques({commit}, {filter}) {
            commit("isLoading", true);

            HTTP.post("/transaction/stats/history_with", {
                account_ids: filter.selectAccounts,
                startDate: filter.startDate,
                endDate: filter.endDate
            }, {params: {}})
                .then((res) => {
                    this.data = res.data;
                    // this.getTotals()

                    commit("isLoading", false);
                    commit("setAcountsStatistiques", res.data.stats);
                    commit("setAcountsTotals", res.data.totals);

                    this.isLoading = false

                })
                .catch((err) => {
                    console.log(err)
                });
        },

        // Vouchers

        async fetchVouchers({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/manager/vouchers", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setVouchers", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchAnalysesVouchers({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/manager/vouchers/analyses", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setAnalysesVouchers", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // fetchAnalysesTotalsVouchers

        async fetchAnalysesTotalsVouchers({commit}, {filter}) {
            commit("statsLoading", true);
            HTTP.get("/manager/vouchers/analyseTotals", {params: {...filter}}).then((res) => {

                commit("statsLoading", false);
                commit("setStatsVouchers", res.data);
            }).catch((err) => {
                commit("statsLoading", false);
            })
        },


        async fetchSuppliers({commit}) {
            commit("suppliersLoading", true);
            HTTP.get("/manager/vouchers/suppliers", {params: {}}).then((res) => {
                commit("suppliersLoading", false);
                commit("setSuppliers", res.data);
            }).catch((err) => {
                commit("suppliersLoading", false);
            })
        },


        async fetchStatistiquesVouchers({commit}, {filter}) {
            commit("statsLoading", true);
            HTTP.get("/manager/vouchers/satistiques", {params: {...filter}}).then((res) => {
                commit("statsLoading", false);
                commit("setStatsVouchers", res.data);
            }).catch((err) => {
                commit("statsLoading", false);
            })
        },

        async fetchVoucherOwners({commit}) {
            commit("ownersLoading", true);
            HTTP.get("/manager/vouchers/owners", {params: {}}).then((res) => {
                commit("ownersLoading", false);
                commit("setVoucherOwners", res.data);
            }).catch((err) => {
                commit("ownersLoading", false);
            })
        },

        // cards
        async fetchCards({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/cards", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setCards", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // fetch list cards
        async fetchListCards({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/cards/list", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setListCards", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // fetch statistiaues
        async fetchCardsStatistiques({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/cards/statistiques", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setCardsStatistiques", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // publicities
        async fetchPublicities({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/V2/publicities", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setPublicities", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchPublicity({commit}, {id}) {
            commit("isLoading", true);
            HTTP.get("/V2/publicities/show/" + id, {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setPublicity", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        // invoices
        async fetchInvoices({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/v1/invoices", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setInvoices", res.data.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchStatistiquesInvoiceMonth({commit}) {
            commit("statsLoading", true);
            HTTP.get("/v1/invoices/statistiques", {params: {}}).then((res) => {
                commit("statsLoading", false);
                commit("setStatsVouchers", res.data);
            }).catch((err) => {
                commit("statsLoading", false);
            })
        },

        async fetchInvoice({commit}, {id}) {
            commit("isLoading", true);
            HTTP.get("/v1/invoices/" + id, {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setInvoice", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchInvoiceVouchers({commit}, {id, filter}) {
            commit("setInvoiceLoading", true);
            HTTP.get("/v1/invoices/vouchers/by_amount/" + id, {params: {...filter}}).then((res) => {
                commit("setInvoiceLoading", false);
                commit("setInvoiceVouchers", res.data.data);
            }).catch((err) => {
                commit("setInvoiceLoading", false);
            })
        },

        async fetchInvoicesRefills({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/v1/invoices/refills", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setInvoicesRefills", res.data.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchInvoiceRefill({commit}, {id}) {
            commit("isLoading", true);
            HTTP.get("/v1/invoices/refills/" + id, {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setInvoiceRefill", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchClients({commit}) {
            commit("clientsLoading", true);
            HTTP.get("/v1/invoices/refills/clients", {params: {}}).then((res) => {
                commit("clientsLoading", false);
                commit("setClients", res.data);
            }).catch((err) => {
                commit("clientsLoading", false);
            })
        },

        async fetchStatistiquesCards({commit}) {
            commit("statsLoading", true);
            HTTP.get("cards/list/statistiques", {params: {}}).then((res) => {
                commit("statsLoading", false);
                commit("setCardsStatistiques", res.data);
            }).catch((err) => {
                commit("statsLoading", false);
            })
        },

        async fetchStatistiquesInvoicesRefills({commit}) {
            commit("statsLoading", true);
            HTTP.get("/v1/invoices/refills/statistiques", {params: {}}).then((res) => {
                commit("statsLoading", false);
                commit("setInvoicesRefillsStatistiques", res.data);
            }).catch((err) => {
                commit("statsLoading", false);
            })
        },

        async fetchCommissions({commit}, {filter}) {
            commit("commissionsLoading", true);
            HTTP.get("/v1/commissions", {params: {...filter}}).then((res) => {
                commit("commissionsLoading", false);
                commit("setCommissions", res.data.data);
            }).catch((err) => {
                commit("commissionsLoading", false);
            })
        },


        async fetchAnalysesVouchersAccount({commit}, {id}) {
            commit("isLoading", true);
            HTTP.get("/manager/vouchers/analyses/" + id, {params: {}}).then((res) => {

                commit("isLoading", false);
                commit("setAnalysesVouchersAccount", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchAnalysesDrivers({commit}, {filter}) {
            commit("isLoading", true);
            HTTP.get("/manager/vouchers/drivers", {params: {...filter}}).then((res) => {
                commit("isLoading", false);
                commit("setAnalysesDrivers", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchAnalyseOneDriver({commit}, {phone}) {
            commit("isLoading", true);
            HTTP.get("/manager/vouchers/driver/" + phone, {params: {}}).then((res) => {
                commit("isLoading", false);
                commit("setAnalysesVouchersAccount", res.data);
            }).catch((err) => {
                commit("isLoading", false);
            })
        },

        async fetchDriverTransactions({commit}, {phone, filter}) {
            commit("isLoadingTransaction", true);
            HTTP.get("/manager/vouchers/driverTransactions/" + phone, {params: {...filter}}).then((res) => {
                commit("isLoadingTransaction", false);
                commit("setDriverTransactions", res.data);
            }).catch((err) => {
                commit("isLoadingTransaction", false);
            })
        },

        async fetchRefillsAccount({commit}, {id, filter}) {
            commit("isLoadingRefills", true);
            HTTP.get("/manager/vouchers/accountRefills/" + id, {params: {...filter}}).then((res) => {
                commit("isLoadingRefills", false);
                commit("setAccountRefills", res.data);
            }).catch((err) => {
                commit("isLoadingRefills", false);
            })
        },

        async fetchCategories({commit}, {filter}) {
            commit("categoriesLoading", true);
            HTTP.get("/V2/categories", {params: {...filter}}).then((res) => {
                commit("categoriesLoading", false);
                commit("setCategories", res.data);
            }).catch((err) => {
                commit("categoriesLoading", false);
            })
        },

        async fetchEcommerceModels({commit}, {filter}) {
            commit("ecommerceModelsLoading", true);
            HTTP.get("v1/ecommerce/models", {params: {...filter}}).then((res) => {
                commit("ecommerceModelsLoading", false);
                commit("setEcommerceModels", res.data.data);
            }).catch((err) => {
                commit("ecommerceModelsLoading", false);
            })
        },

        async fetchEcommerceApis({commit}, {filter}) {
            commit("ecommerceApisLoading", true);
            HTTP.get("v1/ecommerce/apis", {params: {...filter}}).then((res) => {
                commit("ecommerceApisLoading", false);
                commit("setEcommerceApis", res.data.data);
            }).catch((err) => {
                commit("ecommerceApisLoading", false);
            })
        },

        async fetchAccount({commit}) {
            commit("accountLoading", true);
            HTTP.get("v1/gifty/account", {}).then((res) => {
                commit("setAccount", res.data.data);
                commit("accountLoading", false);
            }).catch((err) => {
                commit("accountLoading", false);
            })
        },
    },

});
export default store;
