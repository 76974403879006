import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from "./routes"
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueEasyLightbox from 'vue-easy-lightbox'
import './global'
import './filters'
import './directives'
import roleMiddleware from './middleware/roleMiddleware'
import moment from 'moment'
import vuescroll from 'vuescroll'
import VueMask from 'v-mask'
import CKEditor from 'ckeditor4-vue';
import InfoDialog from "@/components/InfoDialog"
import DateRange from "@/components/form/DateRange";
import Copy from "@/components/Copy";
import bottomNavigationVue from "bottom-navigation-vue";
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.use(VueHtmlToPaper);

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
});


Vue.use(CKEditor);
Vue.use(VueMask);
Vue.use(vuescroll);
Vue.use(VueEasyLightbox);
Vue.use(roleMiddleware);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places',
    },
})
Vue.use(bottomNavigationVue)
Vue.component('InfoDialog', InfoDialog)
Vue.component('DateRange', DateRange)
Vue.component('Copy', Copy)

Vue.config.productionTip = false
Vue.prototype.moment = moment

new Vue({
    render: h => h(App),
    store,
    router,
    vuetify,
}).$mount('#app')

