<template>
  <v-tooltip top color="primary">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"
            @click="copy">
        {{ text }}
      </span>
    </template>
    <span>Copie</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['text'],
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.text);
        this.$successMessage = "Copié dans le presse-papier."
      } catch ($e) {
        console.log($e)
      }
    }
  }
}
</script>

<style scoped>

</style>