import store from "@/store";

export default {
    install(Vue) {
        Vue.prototype.can = function (value) {

            let role = store.state.user.roleName

            let _return = false;

            if (value.includes('|')) {
                let roles = []
                value.split('|').forEach(function (item) {
                    roles.push(item.trim())
                });

                if (roles.includes(role)) {
                    _return = true;
                }
            } else {
                if (role === value) {
                    _return = true;
                }
            }
            return _return;
        }
    }

}


