<template>
  <div>
    <v-snackbar :value="$errorMessage"
                top
                right
                color="error"
                timeout="5000">
         <v-icon left dark>mdi-alert-circle-outline</v-icon>
        <span v-html="$errorMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$errorMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$errorMessage = null
        }, 5000)
      }
    }
  },
}
</script>

<style scoped>

</style>