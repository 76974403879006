const routes = [
    {
        path: '/gifty',
        name: 'dashboard',
        component: () => import('@/views/gifty'),
        meta: {
            title: 'Gifty'
        }
    },
    {
        path: '/otp',
        name: 'otp',
        component: () => import('@/views/gifty/register/Otp.vue'),
        meta: {
            title: 'otp'
        }
    },
    {
        path: '/gifty/register',
        name: 'register',
        component: () => import('@/views/gifty/multistep/Register'),//import('@/views/gifty/Register'),
        meta: {
            title: 'Inscription'
        }
    },
    // {
    //     path: '/gifty/register-particulier',
    //     name: 'register-particulier',
    //     component: () => import('@/views/gifty/register/particulier/FormParticulier.vue'),
    //     meta: {
    //         title: 'Inscription Particulier'
    //     }
    // },
    // {
    //     path: '/gifty/register-professionel',
    //     name: 'register-professionel',
    //     component: () => import('@/views/gifty/register/professionel/FormProfessionel.vue'),
    //     meta: {
    //         title: 'Inscription Particulier'
    //     }
    // },
    {
        path: '/gifty/salepoint',
        name: 'gifty.salepoint',
        component: () => import('@/views/gifty/multistep/Salepoint'),
        meta: {
            title: 'Point de vente'
        }
    },
];

export default routes;
