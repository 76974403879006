import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import fr from 'vuetify/es5/locale/fr';
import store from "@/store";
import '../style/main.css'

Vue.use(Vuetify);


const opts = {
    theme: {
        dark: store.state.dark_theme,
        themes: {
            light: {
                primary: '#FFAB02',
                secondary: '#304156',
                success: '#79D70F',
                green: '#79D70F',
                blue: '#285FD3',
                purple: '#aa00ff',
                opus: '#304156',
                gifty: '#d60c56',
                red: '#FF2442',
                error: '#FF2442',
                warning: '#FFD600',
            },
            dark: {
                primary: '#FFAB02',
                secondary: '#304156',
                success: '#00c853',
                blue: '#285FD3',
                purple: '#aa00ff',
                opus: '#304156',
                gifty: '#d60c56',
            },
        },
    },
    lang: {
        locales: {fr},
        current: 'fr',
    },
    icons: {
        iconfont: 'mdi',
    },
}

export default new Vuetify(opts);
